import React, { useContext } from "react";
import { Stack, Group, ColorSwatch, Text, CheckIcon, rem } from "@mantine/core"
import UserSelectionContext from "../providers/UserSelectionProvider";
import { getHardcodedColor, getHardcodedLabels } from "../utils";
import { dataStreamTypes } from "../types";
import RankingsTable from "../components/Rankings/RankingsTable";


const Rankings = () => {
    const { streamsEnabled, setStreamEnabled } = useContext(UserSelectionContext)

    return (      
        <Stack gap={3}>
            <Group justify="center" style={{ padding: "1%" }}>
                {Object.values(dataStreamTypes).map((t) =>
                    <Group>
                        <ColorSwatch
                            component="button"
                            color={getHardcodedColor(t)}
                            onClick={() => setStreamEnabled({ ...streamsEnabled, [t]: !streamsEnabled[t] })}
                            style={{ color: '#fff', cursor: 'pointer' }}
                        >
                            {streamsEnabled[t] && <CheckIcon style={{ width: rem(12), height: rem(12) }} />}
                        </ColorSwatch>
                        <Text>{getHardcodedLabels(t)}</Text>
                    </Group>
                )}
            </Group>
            <div style={{ width: '100%' }}>
                <RankingsTable />
            </div>
        </Stack>
    );
}

export default Rankings;
