import React, { useContext, useEffect, useState } from "react"
import { MultiSelect, Loader } from "@mantine/core"
import { IoFilter } from "react-icons/io5";
import { css } from '@emotion/css';
import UserSelectionContext from "../../providers/UserSelectionProvider";
import { IoMdClose } from "react-icons/io";
import { getHardcodedColor } from "../../utils";
import { nodeTypes } from "../../types";

interface SearchFieldProps {
  onSearch: (query: string) => Promise<Array<string>>;
  onOptionSelect: (option: string) => void;
  placeholder?: string;
  classNames?: any;
  styles?: any;
  key?: any;
}

const PillsListScrollbar = css`
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #828992 transparent;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #828992;
    border-radius: 10px;
    border: 2px solid transparent;
  }
`;

const IndicationFilterField: React.FC<SearchFieldProps> = ({ onSearch, onOptionSelect, placeholder = "", classNames, styles, key }) => {
  const [query, setQuery] = useState<string>('');
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedIndications, SetSelectedIndications } = useContext(UserSelectionContext)

  useEffect(() => {
    const handleQueryChange = async (value: string) => {
      if (value) {
        setLoading(true);
        const results = await onSearch(value);
        setData(results);
        setLoading(false);
      } else {
        setData([]);
      }
    };
    handleQueryChange(query)
  }, [query])

  return (
    <MultiSelect
      searchable
      classNames={{
        ...classNames,
        pillsList: PillsListScrollbar,
      }}
      clearable
      clearButtonProps={{
        icon: <IoMdClose color="#828992" />
      }}
      styles={{
        ...styles,
        pill: {
          backgroundColor: getHardcodedColor(nodeTypes.disease),
          color: 'white',
        },
        pillsList: {
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
        },
      }}
      value={[...selectedIndications]}
      onChange={(value) => SetSelectedIndications(new Set(value))}
      onSearchChange={(value) => setQuery(value)}
      data={data}
      placeholder={placeholder}
      onOptionSubmit={(option) => {
        onOptionSelect(option);
      }}
      leftSection={
        loading ? <Loader size="xs" /> : <IoFilter size={16} />
      }
      key={key}
    />
  );
};

export default IndicationFilterField;
