import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const HistogramChart = ({ data, domain = [-1, 1], numBins = 20, c = 'steelblue', removeZero = true, roof = 50 }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Dimensions
        const width = canvas.width;
        const height = canvas.height;

        // Chart margins and dimensions
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        // Create histogram bins
        const bins = d3.bin()
            .domain(domain)
            .thresholds(numBins)(data);

        // Scales
        const xScale = d3.scaleBand()
            .domain(bins.map((_, i) => i)) // Bin indices as domain
            .range([0, chartWidth])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain([0, roof])
            .range([chartHeight, 0]);

        const valueScale = d3.scaleLinear()
            .domain(domain)
            .range([0, chartWidth]);

        // Draw bins
        context.save();
        context.translate(margin.left, margin.top);

        bins.forEach((bin, i) => {
            const x = xScale(i);
            const y = yScale(bin.length);
            const binWidth = xScale.bandwidth();
            const binHeight = chartHeight - y;

            if (x !== undefined && y !== undefined) {
                context.fillStyle = bin.x0 <= 0 && bin.x1 > 0? '#ccc' : c;
                context.fillRect(x, y, binWidth, binHeight);
            }
        });

        // Draw x-axis labels
        // const labels = [-1, -0.5, 0, 0.5, 1];
        // context.fillStyle = 'black';
        // context.textAlign = 'center';
        // context.textBaseline = 'top';
        // context.font = '10px Arial';

        // labels.forEach(label => {
        //     const x = valueScale(label);
        //     if (x !== undefined && x >= 0 && x <= chartWidth) {
        //         context.fillText(label, x, chartHeight +5);
        //     }
        // });

        context.restore();
    }, [data, numBins]);

    return (
        <canvas
            ref={canvasRef}
            width={200}
            height={50}
            // style={{ border: '1px solid black' }}
        />
    );
};

export default HistogramChart;
