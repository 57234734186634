import React, { useContext, useEffect, useState } from "react";
import { MantineReactTable, MRT_Icons, MRT_ShowHideColumnsButton, useMantineReactTable } from "mantine-react-table"
import { BarChartHeader, StackedBarCell } from "../../components/Rankings/StackedBarCell";
import { css } from '@emotion/css';
import UserSelectionContext from "../../providers/UserSelectionProvider";
import DataContext from "../../providers/DataProvider";
import { getHardcodedLabels } from "../../utils";
import { scoreTypes } from "../../types";
import { FaSearch } from "react-icons/fa";
import VerticalBarChartHeader from "./WeightsBarChart";

const customIcons: Partial<MRT_Icons> = {
    IconFilter: (props: any) => (
        <FaSearch {...props} />
    ),
}

const RankingsTable = () => {
    const { rankings, data, loadingRankings, scoresDomain } = useContext(DataContext)
    const [filteredRankings, setFilteredRankings] = useState<any>([])
    const { selectedIndications, streamsEnabled, setStreamEnabled } = useContext(UserSelectionContext)
    const [hoveredRowId, setHoveredRowId] = useState(null);

    useEffect(() => {
        setFilteredRankings(selectedIndications.size ? rankings.filter(r => selectedIndications.has(r.name)) : rankings)
    }, [rankings, selectedIndications]);

    const columns = [
        {
            accessorKey: "name",
            header: "Indication",
            size: 100,
            enableSorting: false,
            enableColumnFilter: true,
            enableColumnActions: true,
            enableHiding: false,
            headerStyle: { borderBottom: '1px solid #ccc' },
            mantineFilterTextInputProps: { placeholder: 'Search for indication' },
        },
        {
            id: "empty1",
            columnDefType: 'display',
            header: "",
            size: 5,
            minSize: 1
        },
        {
            accessorKey: scoreTypes.mscore,
            header: getHardcodedLabels(scoreTypes.mscore),
            Cell: ({ cell }) => <StackedBarCell data={{ mscore: cell.getValue() }} showValues={true} domain={scoresDomain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            enableSorting: true,
            enableColumnFilter: false,
            enableColumnActions: true,
            headerStyle: { borderBottom: '1px solid #ccc' },
        },
        {
            id: "empty2",
            columnDefType: 'display',
            header: "",
            size: 5,
            minSize: 1
        },
        {
            accessorKey: scoreTypes.detailedScore,
            header: getHardcodedLabels(scoreTypes.detailedScore),
            Header: (c) => <VerticalBarChartHeader data={data.weights['run_1']['default']} />,
            Cell: ({ cell }) => <StackedBarCell data={cell.getValue()} showValues={cell.row.id === hoveredRowId} domain={scoresDomain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            headerStyle: { borderBottom: '1px solid #ccc' },
        },
    ]

    const table = useMantineReactTable({
        columns, data: filteredRankings,
        enableRowSelection: false,
        enableSelectAll: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        icons: customIcons,
        state: { isLoading: loadingRankings },
        mantineTableHeadCellProps: ({ column }) => {
            if (column.id === 'mrt-row-select') {
                return {
                    style: {
                        width: '10px',
                        color: 'transparent',
                        padding: 0,
                        paddingBottom: 10,
                        verticalAlign: 'bottom'
                    },
                };
            }
            return {
                style: {
                    borderBottom:
                        column.id === 'name'
                            ? '1px solid #000'
                            : column.id === 'mscore'
                                ? '1px solid #000'
                                : column.id === 'detailed_score'
                                    ? '1px solid #000'
                                    : '0px',
                    padding: 0,
                    paddingBottom: 10,
                    verticalAlign: 'bottom'
                },
            };
        },
        mantineTableProps: {
            style: {
                borderCollapse: 'separate',
                borderSpacing: '0',
                tableLayout: "fixed",
            }
        },
        enableGlobalFilter: false,
        initialState: {
            showColumnFilters: true,
            density: 'xs',
            pagination: {
                pageIndex: 0,
                pageSize: 30
            }
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onMouseEnter: () => { setHoveredRowId(row.id) },
            onMouseLeave: () => { setHoveredRowId(null) },
        }),
        enableStickyHeader: true,
        mantineBottomToolbarProps: {
            style: {
                marginBottom: "1px",
                padding: "5px"
            }
        },
        mantinePaperProps: {
            withBorder: false,
            shadow: 'initial',
            style: {
                flex: 1,
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            },
            // Make the table cells smaller
            className: css`
              .mantine-Table-td {
                padding: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            `
        },
        mantineTableContainerProps: {
            style: {
                overflow: 'hidden',
                scrollbarWidth: 'thin',
            },
            onMouseEnter: (e) => {
                e.currentTarget.style.overflow = 'auto';
            },
            onMouseLeave: (e) => {
                e.currentTarget.style.overflow = 'hidden';
            },
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ShowHideColumnsButton table={table} />
            </>
        ),
    })

    return (
        <MantineReactTable table={table} />
    );
}

export default RankingsTable;
