import { dataStreamTypes, nodeTypes, scoreTypes } from "./types";

export function toPercentage(x: number) {
  return Math.round(x * 100) + '%';
}

export const roundToDecimals = (num, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.round(num * factor) / factor;
};

export const mscore = (scores, weights, streamsEnabled) => {
  if (scores === null) return null

  return Object.keys(streamsEnabled).filter(k => streamsEnabled[k]).reduce((sum, key) => {
    const value = scores[key];
    const weight = weights[key];
    return sum + value * weight;
  }, 0);
}

export const WeightedScores = (scores, weights, streamsEnabled) => {
  if (scores === null) return null

  return Object.keys(streamsEnabled).filter(k => streamsEnabled[k]).reduce((details, key) => {
    const value = scores[key as keyof typeof scores];
    const weight = weights[key as keyof typeof weights];
    details[key] = value * weight
    return details;
  }, {});
}

export function getHardcodedColor(domain: string) {
  switch (domain) {
    case nodeTypes.anatomy:
      return "#ff1493";
    case nodeTypes.component:
      return "#00fa9a";
    case nodeTypes.disease:
      return "#a1532c";
    case nodeTypes.drug:
      return "#48d1cc";
    case nodeTypes.exposure:
      return "#f7c4de";
    case nodeTypes.function:
      return "#e9d779";
    case nodeTypes.gene:
      return "#822b78";
    case nodeTypes.pathway:
      return "#808dff";
    case nodeTypes.phynotype:
      return "#fa8072";
    case nodeTypes.process:
      return "#c6d864";
    case scoreTypes.mscore:
      return "gray"
    case dataStreamTypes.diffexpr:
      return "#ff0000"
    case dataStreamTypes.miningNovelty:
      return "#ffa500"
    case dataStreamTypes.miningOverall:
      return "#fad902"
    case dataStreamTypes.otp:
      return "#0bbf71"
    default:
      return "blue"
  }
}

export function getHardcodedLabels(domain: string) {
  switch (domain) {
    case scoreTypes.mscore:
      return "M_Score"
    case scoreTypes.detailedScore:
      return "Detailed Score"
    case dataStreamTypes.diffexpr:
      return "Differential Expression"
    case dataStreamTypes.miningNovelty:
      return "Text Mining Novelty"
    case dataStreamTypes.miningOverall:
      return "Text Mining Overall"
    case dataStreamTypes.otp:
      return "Open Targets Platform"
    default:
      return "Not recognized"
  }
}
