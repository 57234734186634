import React, { useRef, useEffect, useState, useContext } from 'react';
import { Box, Button, Group, Popover, Stack, Tooltip } from "@mantine/core"
import * as d3 from 'd3';
import { getHardcodedColor } from '../../utils';
import { CiLocationArrow1 } from 'react-icons/ci';
import { MdOutlineBarChart, MdOutlineNearMeDisabled } from 'react-icons/md';
import DataContext from '../../providers/DataProvider';
import { roundToDecimals, toPercentage } from '../../utils'

interface VerticalBarChartProp {
    data: { [key: string]: number };
    draggable?: boolean
    hasLabels?: boolean
    transparent?: boolean
    width: number
    height: number
    onWeightsSelected?: (data: { [key: string]: number }) => void
}

const VerticalBarChart: React.FC<VerticalBarChartProp> = ({ data, width, height, draggable = false, hasLabels = false,
    transparent = false, onWeightsSelected = undefined }) => {
    const svgRef = useRef();

    useEffect(() => {
        const weights = Object.entries(data)

        if(onWeightsSelected) onWeightsSelected(Object.fromEntries(weights))

        const margin = { top: 0, right: 5, bottom: 0, left: 0 };
        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleBand()
            .domain(weights.map(w => w[0]))
            .range([0, width])
            .padding(0);

        const y = d3.scaleLinear()
            .domain([0, 1.2])
            .nice()
            .range([height, 0]);

        const bars = svg.selectAll('.bar')
            .data(weights)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', w => x(w[0]))
            .attr('y', w => y(w[1]))
            .attr('width', x.bandwidth())
            .attr('height', w => height - y(w[1]))
            .attr('fill', w => getHardcodedColor(w[0]))
            .attr('opacity', transparent ? 0.4 : 1);

        let labels = null
        if (hasLabels)
            labels = svg.selectAll('.label')
                .data(weights)
                .enter()
                .append('text')
                .attr('class', 'label')
                .attr('x', w => x(w[0]) + x.bandwidth() / 2)
                .attr('y', w => y(w[1]) - 5)
                .attr('text-anchor', 'middle')
                .attr('font-size', '70%')
                .attr('fill', 'black')
                .text(w => toPercentage(w[1]));

        const drag = d3.drag()
            .on('drag', function (event, w, k) {
                const newValue = roundToDecimals(Math.max(0, Math.min(1, y.invert(event.y))), 2);
                w[1] = newValue;

                svg.selectAll('.bar')
                    .data(weights)
                    .attr('y', w => y(w[1]))
                    .attr('height', w => height - y(w[1]));

                svg.selectAll('.label')
                    .data(weights)
                    .attr('y', w => y(w[1]) - 5)
                    .text(w => toPercentage(w[1]));
            })
            .on('end', () => {
                if(onWeightsSelected) onWeightsSelected(Object.fromEntries(weights))
            })

        if (draggable)
            if (hasLabels) labels.call(drag);
            else bars.call(drag)

        return () => {
            d3.select(svgRef.current).selectAll('*').remove();
        };
    }, [data]);

    return <svg style={{ cursor: 'pointer' }} ref={svgRef} />;
};

const AjustableWeights = ({ run_id, wkey, weights }) => {
    const { updateWeights } = useContext(DataContext)
    const [currentWeights, setCurrentWeights] = useState<any>(weights)

    const onWeightsSelected = (data: { [key: string]: number }) => {
        setCurrentWeights(data)
    };

    const isValid = () => roundToDecimals(Object.values(currentWeights).reduce((a, c) => a + c, 0), 2) == 1

    return (
        <Popover position="bottom" trapFocus withArrow shadow="lg">
            <Popover.Target>
                <Box style={{ width: '100%', height: '100px' }}>
                    <VerticalBarChart data={weights} width={100} height={100} draggable={false} />
                </Box>
            </Popover.Target>
            <Popover.Dropdown>
                <Stack>
                    <Group align='flex-end'>
                        <VerticalBarChart
                            data={weights} width={200} height={100}
                            draggable={true} hasLabels={true} onWeightsSelected={onWeightsSelected} />
                        <Tooltip label="Weights should sum to 100%"
                            color='red' disabled={isValid()} position="top">
                            <Button
                                size="compact-sm"
                                variant='outline'
                                leftSection={isValid() ? <CiLocationArrow1 /> : <MdOutlineNearMeDisabled />}
                                color={isValid() ? undefined : 'red'}
                                onClick={isValid()? () => {updateWeights(run_id, wkey, currentWeights)} : undefined}>
                                Apply
                            </Button>
                        </Tooltip>
                    </Group>
                    <Button
                        size="compact-xs"
                        fullWidth
                        variant='light'
                        leftSection={<MdOutlineBarChart />}>
                        Compare to a new scoring weights set
                    </Button>
                </Stack>
            </Popover.Dropdown>
        </Popover>
    );
}

interface VerticalBarChartHeaderProp {
    data: { [key: string]: number };
}

const VerticalBarChartHeader: React.FC<VerticalBarChartHeaderProp> = ({ data }) => {
    return (
        <Group>
            <AjustableWeights run_id='run_1' wkey='default' weights={data}></AjustableWeights>
        </Group>
    );
};

export default VerticalBarChartHeader;
