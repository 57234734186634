import React, { useState } from "react";
import { MantineReactTable, MRT_ToggleFullScreenButton, useMantineReactTable } from "mantine-react-table";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { getHardcodedColor } from "../../utils";
import { css } from '@emotion/css';
import { Text } from "@mantine/core";

export interface PathsData {
    data: {
        node_labels: string[];
        rel_types: string[];
        paths: { node_names: string[], rel_names: string[] }[];
    }
}

const MetaPathTable: React.FC<PathsData> = ({ data }) => {
    const [collapsed, setCollapsed] = useState(true);

    let columns = [
        {
            id: "head-expand",
            columnDefType: 'display',
            Header: () => (
                collapsed ?
                    <IoMdArrowDropright fontSize={25} style={{ cursor: "pointer" }} onClick={() => setCollapsed(false)} />
                    : <IoMdArrowDropdown fontSize={25} style={{ cursor: "pointer" }} onClick={() => setCollapsed(true)} />
            ),
            maxSize: 20,
        }
    ]

    const totalColumns = data.node_labels.length + data.rel_types.length;
    for (let i = 0; i < totalColumns; i++) {

        if (i % 2 === 0 && i / 2 < data.node_labels.length) {
            const nodeIndex = Math.floor(i / 2);
            // node column
            columns.push({
                id: `n_${nodeIndex}`,
                accessorFn: (row) => row.node_names[nodeIndex],
                header: data.node_labels[nodeIndex],
                enableColumnFilter: !collapsed && nodeIndex !== 0 && nodeIndex !== data.node_labels.length - 1,
                enableColumnActions: false,
                enableSorting: false,
            });

        }

        else if (i % 2 !== 0 && (i - 1) / 2 < data.rel_types.length) {
            const relIndex = Math.floor((i - 1) / 2);
            // edge column
            columns.push({
                id: `r_${relIndex}`,
                accessorFn: (row) => row.rel_names[relIndex],
                header: data.rel_types[relIndex],
                enableColumnActions: false,
                enableColumnFilter: false,
                enableSorting: false
            });

        }
    }

    const table = useMantineReactTable({
        columns,
        data: collapsed ? [] : data.paths,
        enableRowSelection: false,
        enableSelectAll: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        renderTopToolbar: false,
        enableGlobalFilter: true,
        enableHiding: false,
        enableStickyHeader: true,
        mantineTableBodyProps: {
            style: { display: collapsed ? "none" : "table-row-group" },
        },
        mantineTableBodyRowProps: {
            style: { borderBottom: "none" },
        },
        mantineTableProps: {
            style: {
                borderCollapse: 'separate',
                borderSpacing: '3px',
                tableLayout: "fixed"
            }
        },
        initialState: {
            density: 'xs',
            showColumnFilters: true,
            pagination: {
                pageIndex: 0,
                pageSize: 5
            },
        },
        mantinePaperProps: {
            withBorder: false,
            shadow: 'initial',
            className: css`
              .mantine-Table-td {
                padding: 5px !important;
                padding-top: 3px !important;
                padding-bottom: 3px !important;
              }
            `
        },
        mantineTableHeadCellProps: ({ column }) => {
            const isNode = column.id.startsWith('n')
            const nodeIdx = Math.floor(column.getIndex() / 2)
            const nodeColor = isNode ? getHardcodedColor(data.node_labels[nodeIdx]) : undefined;
            return {
                align: 'center',
                className: css`
                  .mrt-table-head-cell-labels {
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                  }
                `,
                style: {
                    border: isNode ? `2px solid ${nodeColor}` : '0px',
                    color: isNode ? nodeColor : '#000',
                    textDecoration: !isNode ? 'underline' : undefined,
                    borderRadius: 3,
                    padding: 5,
                    alignContent: 'center'
                },
            }
        },
        mantineTableBodyCellProps: ({ column }) => {
            const isNode = column.id.startsWith('n')
            const nodeIdx = Math.floor(column.getIndex() / 2)
            const nodeColor = isNode ? getHardcodedColor(data.node_labels[nodeIdx]) : undefined;
            return {
                style: {
                    backgroundColor: isNode ? `${nodeColor}` : undefined,
                    borderRadius: 3,
                    padding: 0,
                    textAlign: "center",
                    color: isNode ? '#fff' : undefined,
                    textDecoration: !isNode ? 'underline' : undefined
                },
            }
        },
        mantineBottomToolbarProps: {
            style: {
                display: collapsed ? 'none' : 'block'
            }
        },
        mantineTopToolbarProps: {
            style: {
                display: collapsed ? 'none' : 'block',
                padding: 0
            }
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleFullScreenButton table={table} />
            </>
        ),
    })

    return (
        <MantineReactTable table={table} />
    );
}

export default MetaPathTable;
