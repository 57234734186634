import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { getAllScores, getAllWeights } from '../services/indication';
import Mesh from '../mesh';
import { mscore, WeightedScores } from '../utils';
import UserSelectionContext from './UserSelectionProvider';

// TODO refactor types
interface DataContextType {
    data: { scores: any, weights: any; }
    meshTree: any;
    rankings: any;
    scoresDomain: [number, number];
    loadingMeshTree: boolean;
    loadingRankings: boolean;
    updateWeights: (runId, key, newWeights) => void
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setData] = useState<any>(null);
    const { streamsEnabled } = useContext(UserSelectionContext)
    const [loadingMeshTree, setLoadingMeshTree] = useState<boolean>(true);
    const [loadingRankings, setLoadingRankings] = useState<boolean>(true);

    const updateWeights = (runId, wkey, newWeights) => {
        console.log(runId, wkey, newWeights)
        setData(prevData => ({
            ...prevData,
            weights: {
                ...prevData.weights,
                [runId]: {
                    ...prevData.weights[runId],
                    [wkey]: newWeights
                }
            }
        }))
    }

    useEffect(() => {
        const callGetData = async () => {
            const scores = await getAllScores()
            const weights = await getAllWeights()
            setData({ scores: scores, weights: weights });
        }
        callGetData()
    }, [])

    const rankings = useMemo(() => {
        setLoadingRankings(true)

        if (!data) return []

        const newVal = Object.entries(data.scores).map(([k, v]) => ({
            name: k,
            mscore: mscore(v['run_1'], data.weights['run_1']['default'], streamsEnabled),
            detailed_score: WeightedScores(v['run_1'], data.weights['run_1']['default'], streamsEnabled)
        }))

        setLoadingRankings(false)

        return newVal
    }, [data, streamsEnabled])

    const meshTree = useMemo(() => {
        setLoadingMeshTree(true)

        if (!data) return []

        const newVal = [...Mesh.getTreesWithScores(data.scores, data.weights, streamsEnabled)]

        setLoadingMeshTree(false)

        return newVal
    }, [data, streamsEnabled])

    const scoresDomain = useMemo(() => {
        const flatScores = Object.entries(rankings).flatMap(([_, scores]) => [
            ...Object.values(scores.detailed_score),
            scores.mscore
        ]);

        return [Math.min(...flatScores), 1] // Math.max(...flatScores)];
    }, [rankings])

    return (
        <DataContext.Provider value={{ data, meshTree, rankings, scoresDomain, loadingMeshTree, loadingRankings, updateWeights }}>
            {children}
        </DataContext.Provider>
    );
}

export default DataContext;
