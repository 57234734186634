import React, { useState, useEffect, useContext } from "react"
import { Autocomplete, Loader, MultiSelect } from "@mantine/core"
import { IoFilter, IoSearchOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { nodeTypes } from "../../types";
import { getHardcodedColor } from "../../utils";
import UserSelectionContext from "../../providers/UserSelectionProvider";

interface SearchFieldProps {
  onSearch: (query: string) => Promise<Array<string>>;
  onOptionSelect: (option: string) => void;
  placeholder?: string;
  classNames?: any;
  styles?: any;
}

const MoaSearchField: React.FC<SearchFieldProps> = ({ onSearch, onOptionSelect, placeholder = "", classNames, styles }) => {
  const [query, setQuery] = useState<string>('');
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedMoa, SetSelectedMoa } = useContext(UserSelectionContext)

  useEffect(() => {
    const handleQueryChange = async (value: string) => {
      if (value) {
        setLoading(true);
        const results = await onSearch(value);
        setData(results);
        setLoading(false);
      } else {
        setData([]);
      }
    };
    handleQueryChange(query)
  }, [query])

  return (
    <MultiSelect
      searchable
      classNames={classNames}
      clearable
      clearButtonProps={{
        icon: <IoMdClose color="#828992" />
      }}
      styles={{
        ...styles,
        pill: {
          backgroundColor: getHardcodedColor(nodeTypes.gene),
          color: 'white',
        }
      }}
      value={selectedMoa? [selectedMoa] : []}
      onChange={(value) => SetSelectedMoa(value.length && value[0])}
      onSearchChange={(value) => setQuery(value)}
      data={data}
      placeholder={placeholder}
      onOptionSubmit={(option) => {
        onOptionSelect(option);
      }}
      leftSection={
        loading ? <Loader size="xs" /> : <IoSearchOutline size={16} />
      }
    />
  );
};

export default MoaSearchField;
