import React, { useState } from 'react';
import { PanelResizeHandle } from 'react-resizable-panels';
import { IconCaretLeftFilled, IconCaretRightFilled } from '@tabler/icons-react';

const ResizablePanelHandle = () => {
  const [isHovered, setIsHovered] = useState(false);

  const onHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <PanelResizeHandle>
      <div
        style={{
          display: 'flex',
          cursor: 'ew-resize',
        }}
      >
        <IconCaretLeftFilled size="15" color="#ddd" />
        <div
          style={{
            width: '1px',
            height: '100vh',
            backgroundColor: !isHovered? '#ddd' : 'blue'
          }}
          onMouseEnter={onHover}
          onMouseLeave={onHover}
        />
        <IconCaretRightFilled size="15" color="#ddd" />
      </div>
    </PanelResizeHandle>
  );
};

export default ResizablePanelHandle;
