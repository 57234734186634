import React, { createContext, useState } from 'react';
import { dataStreamTypes } from '../types'

interface UserSelectionContextType {
    selectedMoa: string
    selectedIndications: Set<string>
    streamsEnabled: { [key: string]: boolean }
    SetSelectedMoa: (moa: string) => void
    SetSelectedIndications: (indications: Set<string>) => void
    setStreamEnabled: (d: { [key: string]: boolean }) => void
}

const UserSelectionContext = createContext<UserSelectionContextType | undefined>(undefined);

export const UserSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedMoa, SetSelectedMoa] = useState<string | undefined>(undefined);
    const [selectedIndications, SetSelectedIndications] = useState<Set<string>>(new Set([]));
    const [streamsEnabled, setStreamEnabled] = useState<{ [key: string]: boolean }>(Object.values(dataStreamTypes).reduce(
        (acc, key) => {
            acc[key] = true;
            return acc;
        }, {}));

    return (
        <UserSelectionContext.Provider value={{
            selectedMoa, selectedIndications,
            streamsEnabled, SetSelectedMoa,
            SetSelectedIndications, setStreamEnabled
        }}>
            {children}
        </UserSelectionContext.Provider>
    );
}

export default UserSelectionContext;
