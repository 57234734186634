export enum dataStreamTypes {
    miningOverall = "TxtMining_overall",
    miningNovelty = "TxtMining_novelty",
    diffexpr = "DiffExpr",
    otp = "OTP"
}

export enum Stages {
    search = "search",
    rankings = "rankings",
    evidence = "evidence",
    kg = "kg"
}

export enum nodeTypes {
    disease = "DISEASE",
    gene = "GENE_PROTEIN",
    drug = "DRUG",
    anatomy = "ANATOMY",
    phynotype = "EFFECT_PHENOTYPE",
    exposure = "EXPOSURE",
    process = "BIOLOGICAL_PROCESS",
    component = "CELLULAR_COMPONENT",
    function = "MOLECULAR_FUNCTION",
    pathway = "PATHWAY"
}

export enum scoreTypes {
    mscore = "mscore",
    detailedScore = "detailed_score"
}

export interface MeshNode {
    id: string;
    name: string;
    children?: MeshNode[];
    mscore?: number;
    histData?: number[];
};
