import { MeshNode } from './types';
import { mscore } from './utils';

class Mesh {
    static getTreesWithScores(scores, weights, streamsEnabled): MeshNode[] {
        let treeWithScores = require('./assets/data/mesh_disease_tree.json')
        treeWithScores = this.map(treeWithScores, (node: MeshNode) => (
            {
                ...node,
                mscore: mscore(node.name in scores ? scores[node.name]['run_1'] : null, weights['run_1']['default'], streamsEnabled)
            }
        ))
        treeWithScores = this.map(treeWithScores, (node: MeshNode) => (
            {
                ...node,
                children: node.children?.sort((a, b) => b.mscore === null ? -1 : b.mscore - a.mscore || 0)
            }
        ))
        treeWithScores = this.map(treeWithScores, (node: MeshNode) => (
            {
                ...node,
                histData: this.getAllChildrenScores(node)
            }
        ))
        return treeWithScores.children
    }

    private static map(tree: MeshNode, trans: (n: MeshNode) => MeshNode): MeshNode {
        for (let i = 0; i < tree.children?.length || 0; i++)
            tree.children[i] = this.map(tree.children[i], trans);
        return trans(tree)
    }

    private static getAllChildrenScores(targetNode: MeshNode): number[] {
        const result: number[] = [];

        const traverseAndCollect = (node: MeshNode): boolean => {
            if (node.id === targetNode.id) {
                collectAllDescendants(node);
                return true;
            }
            for (const child of node.children || []) {
                if (traverseAndCollect(child)) return true;
            }
            return false;
        };

        const collectAllDescendants = (node: MeshNode): void => {
            result.push(node.mscore || 0);
            for (const child of node.children || []) {
                collectAllDescendants(child);
            }
        };

        traverseAndCollect(targetNode);
        return result;
    }

    // static getChildren(parentId: string): MeshNode[] {
    //     const searchNode = (node: MeshNode): { id: string; name: string; isLeaf: boolean }[] | null => {
    //         if (node.id === parentId) {
    //             return node.children?.map((child) => ({
    //                 id: child.id,
    //                 name: child.name,
    //                 isLeaf: !child.children || child.children.length === 0,
    //             })) || [];
    //         }
    //         for (const child of node.children || []) {
    //             const result = searchNode(child);
    //             if (result) return result;
    //         }
    //         return null;
    //     };

    //     return searchNode(meshDiseaseTree) || [];
    // }

    // static getRoots(): MeshNode[] {
    //     return this.getChildren('C');
    // }

}

export default Mesh;
