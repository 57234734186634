import api from './api'

const SUB_URL = '/paths'

export const getShortestPaths = async (start: string, end: string) => {
    try {
        const response = await api.post(`${SUB_URL}/shortest`, {start: start, end: end});
        return response.data.paths
      } catch (error) {
        console.error('Error fetching shortest paths:', error);
      }
    };
