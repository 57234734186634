import React, { useContext, useState } from 'react';
import { AppShell, Text, ScrollArea, Center } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import Search from '../../pages/Search';
import Evidence from '../../pages/Evidence';
import Rankings from '../../pages/Rankings';
import PathExplorer from '../../pages/PathExplorer';
import { NavLink } from '../../components/Navigation/NavLink';
import Header from '../../components/Navigation/Header';
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6';
import { MdBarChart } from 'react-icons/md';
import { RiPuzzle2Fill } from 'react-icons/ri';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizablePanelHandle from '../../components/Layout';
import UiStateContext from '../../providers/UiStateProvider';
import { Stages } from '../../types';
import MeshTable from '../Mesh/MeshTable';
import { PiGraphBold } from 'react-icons/pi';

export function CustomAppShell() {
    const { meshPanelOpened, setCurrentStage, currentStage } = useContext(UiStateContext)
    const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

    return (
        <Router>
            <AppShell
                padding="md"
                styles={{
                    main: {
                        height: '100vh',
                        overflowY: 'auto',
                        background: 'white',
                    },
                }}
                header={{ height: 100 }}
                transitionDuration={0}
                navbar={{
                    width: !desktopOpened ? 52 : 250,
                    breakpoint: 'sm',
                    collapsed: { mobile: !mobileOpened },
                }}
            >
                <Header />
                <AppShell.Navbar style={{ backgroundColor: "#f3f3f7" }}>
                    <AppShell.Section>
                        <NavLink
                            key={Stages.rankings}
                            stage={{
                                id: Stages.rankings,
                                name: "Rankings",
                                description: "rankings",
                                icon: MdBarChart
                            }}
                            open={desktopOpened}
                            active={currentStage === Stages.rankings}
                            onClick={() => { setCurrentStage(Stages.rankings) }}
                        />
                        <NavLink
                            key={Stages.evidence}
                            stage={{
                                id: Stages.evidence,
                                name: "Evidence",
                                description: "evidence",
                                icon: RiPuzzle2Fill
                            }}
                            open={desktopOpened}
                            active={currentStage === Stages.evidence}
                            onClick={() => { setCurrentStage(Stages.evidence) }}
                        />
                        <NavLink
                            key={Stages.kg}
                            stage={{
                                id: Stages.kg,
                                name: "Path Explorer",
                                description: "Path Explorer",
                                icon: PiGraphBold
                            }}
                            open={desktopOpened}
                            active={currentStage === Stages.kg}
                            onClick={() => { setCurrentStage(Stages.kg) }}
                        />
                    </AppShell.Section>
                    <AppShell.Section
                        style={{
                            width: '100%',
                            borderTop: '1px solid #e4e4e6',
                            position: 'absolute',
                            bottom: '0px',
                        }}
                    >
                        <NavLink
                            key="collapse"
                            stage={{
                                id: undefined,
                                name: "Collapse",
                                description: "",
                                icon: desktopOpened ? FaAnglesLeft : FaAnglesRight
                            }}
                            open={desktopOpened}
                            active={false}
                            onClick={toggleDesktop}
                        />
                    </AppShell.Section>
                </AppShell.Navbar>
                <AppShell.Main>
                    <ScrollArea>
                        <PanelGroup direction="horizontal">
                            <Panel minSize={50} order={1}>
                                <Routes>
                                    <Route path="/" element={<Search />} />
                                    <Route path="/rankings" element={<Rankings />} />
                                    <Route path="/evidence" element={<Evidence />} />
                                    <Route path="/kg" element={<PathExplorer />} />
                                </Routes>
                            </Panel>
                            {meshPanelOpened && (
                                <>
                                    <ResizablePanelHandle />
                                    <Panel minSize={25} defaultSize={50} order={2}>
                                        <MeshTable />
                                    </Panel>
                                </>
                            )}
                        </PanelGroup>
                    </ScrollArea>
                </AppShell.Main>
            </AppShell>
        </Router>
    );
}
