import React, { } from 'react';
import { UserSelectionProvider } from './providers/UserSelectionProvider';
import { UiStateProvider } from './providers/UiStateProvider';
import { CustomAppShell } from './components/Navigation/CustomAppShell';
import { DataProvider } from './providers/DataProvider';

export function App() {
  return (
    <UserSelectionProvider>
      <DataProvider>
        <UiStateProvider>
          <CustomAppShell />
        </UiStateProvider>
      </DataProvider>
    </UserSelectionProvider>
  );
}
