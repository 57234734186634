import React, { useState, useEffect, useContext } from "react";
import {
    MantineReactTable, MRT_Icons, MRT_Row, MRT_RowSelectionState, MRT_ShowHideColumnsButton,
    MRT_ToggleFullScreenButton, useMantineReactTable
} from "mantine-react-table";
import { Group, Text, Tooltip } from "@mantine/core"
import { StackedBarCell } from "../Rankings/StackedBarCell";
import DataContext from "../../providers/DataProvider";
import HistogramChart from "./HistogramChart";
import { css } from '@emotion/css';
import UserSelectionContext from "../../providers/UserSelectionProvider";
import { MeshNode, scoreTypes } from "../../types";
import { BiSolidArrowFromBottom } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { LuDot } from "react-icons/lu";
import { FaSearch } from "react-icons/fa";
import { getHardcodedColor } from "../../utils";

const customIcons: Partial<MRT_Icons> = {
    IconFilter: (props: any) => (
        <FaSearch {...props} />
    ),
}

const MeshTableExpand: React.FC<{ row: MRT_Row<MeshNode>, handler: () => void }> = ({ row, handler }) => {
    const parents = row.getParentRows().map(r => r.original.name)
    return (
        <Group wrap="nowrap">
            {parents.map((parent) => (
                <Tooltip label={parent} >
                    <span style={{ display: 'inline-block' }}>
                        <BiSolidArrowFromBottom color="#ccc" style={{ cursor: 'help' }} />
                    </span>
                </Tooltip>
            ))}
            {row.getCanExpand() ?
                (row.getIsExpanded() ?
                    <IoMdArrowDropdown style={{ cursor: 'pointer' }} onClick={handler} />
                    : <IoMdArrowDropright style={{ cursor: 'pointer' }} onClick={handler} />)
                : <LuDot color="#ccc" />
            }
        </Group>
    );
}

const MeshTable = () => {
    const { selectedIndications, SetSelectedIndications } = useContext(UserSelectionContext)
    const { meshTree, loadingMeshTree, scoresDomain } = useContext(DataContext)
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (firstLoad) return
        const newSelection = Object.keys(rowSelection);
        if (JSON.stringify([...selectedIndications]) !== JSON.stringify(newSelection)) {
            SetSelectedIndications(new Set(newSelection));
        }
    }, [rowSelection]);

    useEffect(() => {
        const oldRowSelection = Object.keys(rowSelection);
        if (JSON.stringify([...selectedIndications]) !== JSON.stringify(oldRowSelection)) {
            setRowSelection(Object.fromEntries(
                Array.from(selectedIndications, (indication) => [indication, true])
            ));
        }
        if (firstLoad) setFirstLoad(false)
    }, [selectedIndications]);

    const columns = [
        {
            accessorKey: "name",
            header: "Indication",
            Cell: ({ cell, renderedCellValue }) => {
                return (<div style={{ maxWidth: 200, marginLeft: `${cell.row.getParentRows().length * 20}px` }}>

                    <Tooltip label={renderedCellValue} >
                        <Text size="xs" truncate="end">{renderedCellValue}</Text>
                    </Tooltip>
                </div>)
            },
            enableSorting: false,
            enableColumnFilter: true,
            filterFn: 'contains',
            enableColumnActions: false,
            mantineFilterTextInputProps: { placeholder: 'Search for indication' },
        },
        {
            accessorKey: "mscore",
            header: "M_score",
            Cell: ({ cell }) => {
                if (cell.getValue() === null)
                    return <Text size="xs" c='dimmed' ta='left'>Not computed.</Text>
                return <StackedBarCell
                    style={{ maxWidth: 100 }}
                    data={{ mscore: cell.getValue() }}
                    showValues={true}
                    domain={scoresDomain} />
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false
        },
        {
            accessorKey: "histData",
            header: "Histogram",
            Cell: ({ cell }) => {
                if (!cell.row.original.children.length)
                    return <Text size="xs" c='dimmed' ta='left'>No children.</Text>
                if (!cell.getValue() || !cell.getValue().reduce((acc, c) => acc + c, 0))
                    return <Text size="xs" c='dimmed' ta='left'>All not computed or zero.</Text>
                return <HistogramChart c={getHardcodedColor(scoreTypes.mscore)} data={cell.getValue()} />
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false
        }
    ]

    const table = useMantineReactTable({
        columns,
        data: meshTree,
        enableRowSelection: (row) => row.original.mscore !== null,
        enableSelectAll: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        enableSubRowSelection: false,
        enableStickyHeader: true,
        enableGlobalFilter: false,
        enableExpanding: true,
        enableExpandAll: false,
        filterFromLeafRows: true,
        positionToolbarAlertBanner: 'none',
        getSubRows: (originalRow) => originalRow.children,
        getRowId: (originalRow) => originalRow.name,
        onRowSelectionChange: setRowSelection,
        state: { rowSelection, isLoading: meshTree.length == 0 || loadingMeshTree },
        icons: customIcons,
        initialState: {
            showColumnFilters: true,
            density: 'xs',
            pagination: {
                pageIndex: 0,
                pageSize: 30
            },
            columnOrder: [
                'mrt-row-select',
                'mrt-row-expand',
                'name',
                'mscore',
                'histData'
            ]
        },
        mantinePaperProps: {
            withBorder: false,
            shadow: 'initial',
            style: {
                flex: 1,
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            },
            // Make the table cells smaller
            className: css`
              .mantine-Table-td {
                padding: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            `
        },
        mantineTableBodyRowProps: {
            style: { borderBottom: "block" },
        },
        mantineSelectCheckboxProps: {
            size: 'xs'
        },
        displayColumnDefOptions: {
            'mrt-row-select': {
                size: 5,
                mantineTableHeadCellProps: {
                    style: {
                        color: 'transparent',
                        padding: 0
                    }
                }
            },
            'mrt-row-expand': {
                Cell: ({ cell }) => <MeshTableExpand row={cell.row} handler={cell.row.getToggleExpandedHandler()} />,
                size: 5,
                mantineTableHeadCellProps: {
                    style: {
                        color: 'transparent'
                    }
                }
            },
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
            </>
        ),
        // renderTopToolbarCustomActions: ({ table }) => (
        //     <Button variant="outline" leftSection={<IoFilterOutline />}
        //         onClick={() => {
        //             SetSelectedIndications(new Set(Object.keys(table.getState().rowSelection)))
        //         }}
        //     >
        //         Filter by selections
        //     </Button>
        // ),
    })

    return (
        <MantineReactTable table={table} />
    );
}

export default MeshTable;
