import api from './api'

const SUB_URL = '/indications'

export const searchIndications = async (q: string) => {
    try {
        const response = await api.post(`${SUB_URL}/search`, {q: q});
        return response.data.results
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
};

export const getAllWeights = async (run_id: string | undefined = "run_1") => {
  try {
      const response = await api.get(`${SUB_URL}/${run_id}/weights/all`);
      return response.data
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
};

export const getAllScores = async (run_id: string | undefined = "run_1") => {
  try {
      const response = await api.get(`${SUB_URL}/${run_id}/scores/all`);
      return response.data
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
};
