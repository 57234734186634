import React, { useEffect, useState } from 'react';
import { getShortestPaths } from '../services/paths';
import MetaPathTable from '../components/PathExplorer/MetaPathTable';
import { Center, Loader } from '@mantine/core'

const PathExplorer: React.FC = () => {
    const [data, setData] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const callgetShortestPaths = async () => {
            const response = await getShortestPaths("psoriasis", "IL1RL2")
            setData(response)
            setIsLoading(false)
        }
        callgetShortestPaths()
    }, [])

    return (
        <>
            {isLoading ? 
                <Center style={{height:'80vh'}}><Loader></Loader></Center>
            : 
                data.map((d, index) => <MetaPathTable key={`data-${index}`} data={d} />)
            }
        </>
    );
}

export default PathExplorer;
