import React, { createContext, useState } from 'react';
import { Stages } from '../types';

interface UiStateContextType {
    currentStage: Stages
    meshPanelOpened: boolean
    detailsPanelOpened: boolean
    setCurrentStage: (stage: Stages) => void
    SwitchMeshPanelOpened: () => void
    SwitchDetailsPanelOpened: () => void
}

const UiStateContext = createContext<UiStateContextType | undefined>(undefined);

export const UiStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentStage, setCurrentStage] = useState<Stages>(Stages.search);
    const [meshPanelOpened, SetMeshPanelOpened] = useState<boolean>(false);
    const [detailsPanelOpened, SetDetailsPanelOpened] = useState<boolean>(false);

    const SwitchMeshPanelOpened = () => {
        SetMeshPanelOpened(!meshPanelOpened);
    }

    const SwitchDetailsPanelOpened = () => {
        SetDetailsPanelOpened(!detailsPanelOpened);
    }

    return (
        <UiStateContext.Provider value={{ currentStage, meshPanelOpened, detailsPanelOpened, setCurrentStage, SwitchMeshPanelOpened, SwitchDetailsPanelOpened }}>
            {children}
        </UiStateContext.Provider>
    );
}

export default UiStateContext;
